@import '../../../css/Colors';

/*.Logo {
  background: $accent-standard;
  border: 2px solid white;
  border-radius: 15px;
  cursor: pointer;

  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

.Logo:hover {
  background: $accent2-standard;
}*/

.Logo {
  cursor: pointer;
}

.Navbar {
  background-color: $secondary;
  color: white;
  height: 7vh;
  min-height: 50px;
}

.NavLink {
  color: white;
  font-weight: bold;

  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.NavLink:hover {
  color: $accent-standard;
}

.NavLink:hover::after {
  background-color: whitesmoke;
  height: 100%;
}


