@import '../../../css/Colors';

.FAQWrapper {
  /*background-color: #282c34;*/
  min-height: 93vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  font-weight: bolder;
  color: $fourth;
}