$primary: whitesmoke; // Light Mode?
$secondary: black;
$third: #9b9d9e;
$fourth: #828383;
$fifth: #161515; // Dark mode?

$accent-standard: #AA923E;
$accent-dbd: #FF6100;

$accent2-standard: #46370a;

$hover-standard: #AA923E;
$hover-dbd: #FF6100;

$visited-standard: #AA923E;
$visited-dbd: #FF6100;





$maybe: #DAF7A6;
$maybe2: #13294B;
