@import '../../../css/Colors';

.Table {
  font-weight: bold;
  border: 2px solid black;
}

td:nth-child(1) {
  width: 50px;
  background: #ddd;
}

/* the second */
td:nth-child(2) {
  width: 400px;
  background: #ccc;
}

/* the third */
td:nth-child(3) {
  width: 250px;
  background: #bbb;
}

.Table>thead {
  font-weight: bolder;
  font-size: larger;
}

.Table>tbody>tr:nth-child(odd)>td,
.Table>tbody>tr:nth-child(odd)th {
  background-color: black;
  color: whitesmoke;
}

.Table>tbody>tr:nth-child(even)>td,
.Table>tbody>tr:nth-child(even)th {
  background-color: $accent-standard;
  color: whitesmoke;
}

.TableShell {
  padding: 3vh 10vw;
}