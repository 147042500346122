@import '../../../css/Colors';

.HomePage {
  min-height: 93vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  font-weight: bolder;
  color: $fourth;

}

.HomePageHeader {
  display: inline-block;
  background: whitesmoke;
  border: 3px solid goldenrod;
  border-radius: 15px;

  flex-direction: column;
  justify-content: center;


  min-height: 100px;
  min-width: 350px;

  pointer-events: none;
}

.HomePageHeaderDiv {
  padding: 0 0.4em 0 0;
}

.HomePageLogo {
  height: 20vmin;
  pointer-events: none;
}
