@import 'src/css/Colors';

//-----------------------------------------------------
.buttonClash {
  user-select: none;
  color: $accent-standard;
  padding: 8px 16px;
  background: none;
  border: none;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
  &:after, &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 1px solid black;
    -webkit-transition: transform 0.5s, border 0.55s;
    -moz-transition: transform 0.5s, border 0.55s;
    -o-transition: transform 0.5s, border 0.55s;
    transition: transform 0.5s, border 0.55s;
  }
  &:hover {
    color: whitesmoke;
    &:after {
      transform: translate(3px, 3px);
      border: 1px solid $accent-standard;
    }
    &:before {
      transform: translate(-3px, -3px);
      border: 1px solid $accent-standard;
    }
  }
  &:active {
    color: $accent-standard !important;
    &:after {
      border-color: whitesmoke;
    }
    &:before {
      border-color: whitesmoke;
    }
  }
  &:focus {
    color: $accent2-standard;
  }
}


//-----------------------------------------------------
.buttonSlidingBg {
  padding: 12px 17px;
  margin: 25px;
  font-family: 'OpenSansBold', sans-serif;
  border: 3px solid #31302B;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  animation-name: animate-out;
  animation-duration: 0.5s;
  animation-repeat-count: 1;
  animation-fill-mode: forwards;
}
.buttonSlidingBg:hover {
  animation-name: animate-in;
}

@keyframes animate-in {
  0% {
    box-shadow: inset 0 0 0 0 #31302B;
    color:#31302B;
    background: #FFF;
  }
  100% {
    box-shadow: inset 100px 0 0 0 #31302B;
    color:#FFF;
    background: #FFF;
  }
}
@keyframes animate-out {
  0% {
    box-shadow: inset 0 0 0 0 #FFF;
    background: #31302B;
    color:#FFF;
  }
  100% {
    box-shadow: inset 100px 0 0 0 #FFF;
    background: #31302B;
    color:#31302B;
  }
}
//-----------------------------------------------------