$lightThemeBackground: "../../../assets/backgrounds/GoldenLight.jpg";
$drakenThemeBackground: "../../../assets/backgrounds/DrakenLight.jpg";


.DivBackgroundWrapper {
  background-attachment: fixed;
  background-image: url($lightThemeBackground);
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 93vh;

  -webkit-transition: background-image 1s ease-out;
  -moz-transition: background-image 1s ease-out;
  -o-transition: background-image 1s ease-out;
  transition: background-image 1s ease-out;
}

.DivBackgroundWrapperDark {
  background-attachment: fixed;
  background-image: url($drakenThemeBackground);
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 93vh;

  -webkit-transition: background-image 1s ease-out;
  -moz-transition: background-image 1s ease-out;
  -o-transition: background-image 1s ease-out;
  transition: background-image 1s ease-out;
}